// extracted by mini-css-extract-plugin
export var column = "CountriesDashboardOptions__column__uvLRT";
export var flex = "CountriesDashboardOptions__flex__C5_hS";
export var flexColumn = "CountriesDashboardOptions__flexColumn__XMcCr";
export var gap1 = "CountriesDashboardOptions__gap1__NGnEe";
export var gap2 = "CountriesDashboardOptions__gap2__crwK0";
export var gap3 = "CountriesDashboardOptions__gap3__JO3d8";
export var gap4 = "CountriesDashboardOptions__gap4__ScC_L";
export var gap5 = "CountriesDashboardOptions__gap5__I7X44";
export var popoverBody = "CountriesDashboardOptions__popoverBody__DFVez";
export var popoverBodyLink = "CountriesDashboardOptions__popoverBodyLink__sPR7D";
export var popoverBodyLinkIcon = "CountriesDashboardOptions__popoverBodyLinkIcon__H5l6n";
export var row = "CountriesDashboardOptions__row___oSrj";